import {NgModule, APP_INITIALIZER} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule,NoopAnimationsModule} from '@angular/platform-browser/animations';
// import {GestureConfig} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {InMemoryWebApiModule} from 'angular-in-memory-web-api';
import {InMemoryDataService} from './shared/inmemory-db/inmemory-db.service';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/* NgRx */
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {refdataReducer} from './views/refdata/state/refdata.reducer';
import {coreReducer} from './views/core/state/core.reducer';
import {RefdataEffects} from './views/refdata/state/refdata.effects';
import {userReducer} from './views/user/state/user.reducer';
import {UserEffects} from './views/user/state/user.effects';
import {ApiInterceptorService} from './api-interceptor.service';
import {CoreModule} from './views/core/core.module';
//TD: import {JWT_OPTIONS, JwtHelperService, JwtModule, JwtModuleOptions} from '@auth0/angular-jwt';
import { BlockUIModule } from 'ng-block-ui';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppConfig } from './app-config.module';
import { HttpModule } from '@angular/http';
import { EditorModule } from '@progress/kendo-angular-editor';
import { AlphaNumericDirective } from './shared/directives/alpha-numeric.directive';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

/*
//TD: Not in use!?
const JWT_Module_Options: JwtModuleOptions = {
  config: {
  }
};
*/

// Add this function
export function initConfig(config: AppConfig){
  return () => config.load() 
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, {passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    StoreModule.forRoot({core: coreReducer, refdata: refdataReducer, user: userReducer}),
    EffectsModule.forRoot([RefdataEffects, UserEffects]),
    StoreDevtoolsModule.instrument({
      name: 'SAR DevTools',
      maxAge: 25,
      logOnly: environment.production
    }),
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    CoreModule,
    BlockUIModule.forRoot(),
    GridModule,
    InputsModule,
    LayoutModule,
    HttpModule,
    EditorModule
  ],
  exports: [
    TranslateModule
  ],
  declarations: [AppComponent],
  providers: [
    // {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    AppConfig,
    { provide: APP_INITIALIZER,
      useFactory: initConfig, // And use it here
      deps: [AppConfig], 
      multi: true } ,
    HttpClientModule

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
