import { NgModule, InjectionToken, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IEnvModel } from './i-env.model';

@Injectable()
export class AppConfig {
  apiEndpoint: string;

    private config: Object = {
        backendApi : environment.apiURL,
    };
    private env: IEnvModel = {
        env: "development"
    };

    constructor(private http: HttpClient) {
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv(key: any): any {
        return this.env[key];
    }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load() {
        return new Promise((resolve, reject) => {
            var rndNr = Math.ceil(Math.random() * 999999).toString() + new Date().getUTCMilliseconds().toString();
            this.http.get('/assets/env.json?t=' + rndNr).subscribe( (envResponse : IEnvModel) => {
                this.env = envResponse;
                let request:any = this.http.get('/assets/config.' + this.env.env + '.json');
                if (request) {
                    request
                        .subscribe((responseData) => {
                            this.config = responseData;
                            resolve(true);
                        });
                } else {
                    console.error('Env config file "env.json" is not valid');
                    resolve(true);
                }
            });

        });
    }
}
