<kendo-window *ngIf="helpOpened" (close)="closeWindow()" [(width)]="windowWidth" [(height)]="windowHeight">
  <kendo-window-titlebar>
    <div style="display:flex;align-items:center;font-size: 18px; line-height: 1.3em;width: 100%">
      <span class="k-icon k-i-help" style="font-size: 1.1em;margin-right: 10px"></span>
      <span>{{windowTitle}}</span>
    </div>
    <button kendoWindowMaximizeAction *ngIf="currentVideo != null"></button>
    <button kendoWindowRestoreAction></button>
    <button kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  
  <div class="player" *ngIf="currentVideo != null">
    <video
      #videoplayer
      controls 
      class="player__video"
      [src]="currentVideo"
    ></video>
  </div>
  <div style="position: relative;height: 100%;" *ngIf="currentVideo == null">
    <div style="text-align: center;font-size: 1.5em;line-height: 2em;transform: translateY(50%);height: 68.5%;">
      Sorry!<br>
      Help is not available for this page yet.
    </div>
  </div>
</kendo-window>