<mat-toolbar class="topbar topbar-white">
  <!-- Sidenav toggle button -->
  <button mat-icon-button id="sidenavToggle"
    (click)="toggleSidenav()"
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <!-- Sidenav toggle collapse -->
  <button 
  *ngIf="layoutConf.sidebarStyle !== 'closed'"
  mat-icon-button
  id="collapseToggle"
  fxHide.lt-md="true" 
  (click)="toggleCollapse()"
  matTooltip="{{'Toggle Collapse' | translate}}"
  class="toggle-collapsed">
  <mat-icon>chevron_left</mat-icon>
  </button>
  <!-- Search form -->
  <div *ngIf="this.visible"
  fxFlex
  fxHide.lt-sm="true" 
  class="search-bar"
  style="display: none">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon> 
      <input autofocus="true" placeholder="Search" type="text">
    </form>
  </div>
  <span fxFlex></span>
  <!-- Language Switcher -->
  <!--<mat-select -->
  <!--placeholder=""-->
  <!--id="langToggle"-->
  <!--[style.width]="'auto'"-->
  <!--name="currentLang"-->
  <!--[(ngModel)]="currentLang" -->
  <!--(selectionChange)="setLang($event)">-->
    <!--<mat-option -->
    <!--*ngFor="let lang of availableLangs" -->
    <!--[value]="lang.code" ngDefaultControl>{{ lang.name }}</mat-option>-->
  <!--</mat-select>-->
  <!--&lt;!&ndash; Theme Switcher &ndash;&gt;-->
  <!--<button -->
  <!--mat-icon-button-->
  <!--id="schemeToggle" -->
  <!--[style.overflow]="'visible'"-->
  <!--matTooltip="Color Schemes"-->
  <!--[matMenuTriggerFor]="themeMenu"-->
  <!--class="topbar-button-right">-->
    <!--<mat-icon>format_color_fill</mat-icon>-->
  <!--</button>-->
  <!--<mat-menu #themeMenu="matMenu">-->
    <!--<mat-grid-list-->
    <!--class="theme-list" -->
    <!--cols="2" -->
    <!--rowHeight="48px">-->
      <!--<mat-grid-tile -->
      <!--*ngFor="let theme of egretThemes"-->
      <!--(click)="changeTheme(theme)">-->
        <!--<div mat-menu-item [title]="theme.name">-->
          <!--<div [style.background]="theme.baseColor" class="egret-swatch"></div>-->
          <!--<mat-icon class="active-icon" *ngIf="theme.isActive">check</mat-icon>-->
        <!--</div>-->
      <!--</mat-grid-tile>-->
    <!--</mat-grid-list>-->
  <!--</mat-menu>-->
  <!--&lt;!&ndash; Notification toggle button &ndash;&gt;-->
  <!--<button -->
  <!--mat-icon-button-->
  <!--matTooltip="Notifications" -->
  <!--(click)="toggleNotific()"-->
  <!--[style.overflow]="'visible'" -->
  <!--class="topbar-button-right">-->
    <!--<mat-icon>notifications</mat-icon>-->
    <!--<span class="notification-number mat-bg-warn">3</span>-->
  <!--</button>-->

  <span style="font-size: 0.7em;">{{getName()}}</span>
  <!-- Top left user menu --> 
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button" style="margin-right:10px">
    <img src="{{getPhotoUrl()}}" alt="">
  </button>

  <mat-menu #accountMenu="matMenu" xPosition="after">
    <button mat-menu-item (click)="navigateToProfile()">
      <mat-icon>account_box</mat-icon>
      <span>{{'Profile' | translate}}</span>
    </button>
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{'Sign out' | translate}}</span>
    </button>
  </mat-menu>
</mat-toolbar>
