import {Action} from '@ngrx/store';
import {IFeedbackModel} from '../../core/models/i-feedback.model';
import {ILoginModel} from '../models/i-login.model';
import {ICreateUserModel} from '../models/create-user/i-create-user.model';
import {IAuthModel} from '../models/i-auth.model';
import {ISearchUserModel} from '../../sar/models/meeting/i-search-user.model';
import {IUserDashboardModel} from '../models/user-list/i-user-dashboard.model';
import {ICoreSearchModel} from '../../core/models/i-core-search.model';
import {IRoleModel} from '../models/create-user/i-role.model';
import {IRoleAttributeModel} from '../models/create-user/i-role-attribute.model';
import {IUserModel} from '../models/i-user.model';
import {IUpdateUserModel} from '../models/create-user/i-update-user.model';

export enum UserActionTypes {
  InitializeUser = '[User] InitializeUser',
  ShowGlobalFeedback = '[User] Global Feedback',
  StartUser = '[User] Start',
  Create = '[User] Create',
  CreateSuccess = '[User] Create Success',
  CreateFail = '[User] Create Fail',
  Update = '[User] Update',
  UpdateSuccess = '[User] Update Success',
  UpdateFail = '[User] Update Fail',
  Login = '[User] Login',
  Logout = '[User] Logout',
  LoginSuccess = '[User] Login Success',
  LoginFail = '[User] Login Fail',
  Search = '[User] Search',
  SearchSuccess = '[User] Search Success',
  SearchFail = '[User] Search Fail',
  GetDashboardUsers = '[User] GetDashboardUsers',
  GetDashboardUsersSuccess = '[User] GetDashboardUsers Success',
  GetDashboardUsersError = '[User] GetDashboardUsers Error',
  GetUsers = '[User] GetUsers',
  GetUsersSuccess = '[User] GetUsers Success',
  GetUsersError = '[User] GetUsers Error',
  GetRoles = '[User] GetRoles',
  GetRolesSuccess = '[User] GetRoles Success',
  GetRolesError = '[User] GetRoles Error',
  GetRoleAttributes = '[User] GetRoleAttributes',
  GetRoleAttributesSuccess = '[User] GetRoleAttributes Success',
  GetRoleAttributesError = '[User] GetRoleAttributes Error',
  SelectedUserId = '[User] SelectedUserId',
  GetUser = '[User] GetUser',
  GetUserSuccess = '[User] GetUser Success',
  GetUserError = '[User] GetUser Error',
}

export class ShowGlobalFeedback implements Action {
  readonly type = UserActionTypes.ShowGlobalFeedback;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class InitializeUser implements Action {
  readonly type = UserActionTypes.InitializeUser;
  constructor() {    
  }
}

export class StartUser implements Action {
  readonly type = UserActionTypes.StartUser;
  constructor(public payload: string) {
  }
}

export class Login implements Action {
  readonly type = UserActionTypes.Login;
  constructor(public payload: ILoginModel) {
  }
}

export class Logout implements Action {
  readonly type = UserActionTypes.Logout;
  constructor() {
  }
}

export class LoginSuccess implements Action {
  readonly type = UserActionTypes.LoginSuccess;
  constructor(public payload: IAuthModel) {
  }
}

export class LoginFail implements Action {
  readonly type = UserActionTypes.LoginFail;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class Create implements Action {
  readonly type = UserActionTypes.Create;
  constructor(public payload: ICreateUserModel) { // ICreateUserModel
  }
}

export class CreateSuccess implements Action {
  readonly type = UserActionTypes.CreateSuccess;
  constructor(public payload: string) {
  }
}

export class CreateFail implements Action {
  readonly type = UserActionTypes.CreateFail;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class Update implements Action {
  readonly type = UserActionTypes.Update;
  constructor(public payload: IUpdateUserModel) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = UserActionTypes.UpdateSuccess;
  constructor(public payload: string) {
  }
}

export class UpdateFail implements Action {
  readonly type = UserActionTypes.UpdateFail;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class Search implements Action {
  readonly type = UserActionTypes.Search;
  constructor(public payload: string) { // ICreateUserModel
  }
}

export class SearchSuccess implements Action {
  readonly type = UserActionTypes.SearchSuccess;
  constructor(public payload: ISearchUserModel[]) {
  }
}

export class SearchFail implements Action {
  readonly type = UserActionTypes.SearchFail;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetUsers implements Action {
  readonly type = UserActionTypes.GetUsers;
  constructor(public payload: ICoreSearchModel) { // ICreateUserModel
  }
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GetUsersSuccess;
  constructor(public payload: IUserModel[]) {
  }
}

export class GetUsersError implements Action {
  readonly type = UserActionTypes.GetUsersError;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetDashboardUsers implements Action {
  readonly type = UserActionTypes.GetDashboardUsers;
  constructor(public payload: ICoreSearchModel) { // ICreateUserModel
  }
}

export class GetDashboardUsersSuccess implements Action {
  readonly type = UserActionTypes.GetDashboardUsersSuccess;
  constructor(public payload: IUserDashboardModel[]) {
  }
}

export class GetDashboardUsersError implements Action {
  readonly type = UserActionTypes.GetDashboardUsersError;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetRoles implements Action {
  readonly type = UserActionTypes.GetRoles;
  constructor() { // ICreateUserModel
  }
}

export class GetRolesSuccess implements Action {
  readonly type = UserActionTypes.GetRolesSuccess;
  constructor(public payload: IRoleModel[]) {
  }
}

export class GetRolesError implements Action {
  readonly type = UserActionTypes.GetRolesError;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class GetRoleAttributes implements Action {
  readonly type = UserActionTypes.GetRoleAttributes;
  constructor() { // ICreateUserModel
  }
}

export class GetRoleAttributesSuccess implements Action {
  readonly type = UserActionTypes.GetRoleAttributesSuccess;
  constructor(public payload: IRoleAttributeModel[]) {
  }
}

export class GetRoleAttributesError implements Action {
  readonly type = UserActionTypes.GetRoleAttributesError;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export class SelectedUserId implements Action {
  readonly type = UserActionTypes.SelectedUserId;
  constructor(public payload: string) { // UserId
  }
}

export class GetUser implements Action {
  readonly type = UserActionTypes.GetUser;
  constructor(public payload: string) { // UserId
  }
}

export class GetUserSuccess implements Action {
  readonly type = UserActionTypes.GetUserSuccess;
  constructor(public payload: IUserModel) {
  }
}

export class GetUserError implements Action {
  readonly type = UserActionTypes.GetUserError;
  constructor(public payload: IFeedbackModel[]) {
  }
}

export type UserActions =
  ShowGlobalFeedback |
  InitializeUser |
  StartUser |
  Login |
  LoginSuccess |
  LoginFail |
  Logout |
  Create |
  CreateSuccess |
  CreateFail |
  Update |
  UpdateSuccess |
  UpdateFail |
  Search |
  SearchSuccess |
  SearchFail |
  GetUsers |
  GetUsersSuccess |
  GetUsersError |
  GetDashboardUsers |
  GetDashboardUsersSuccess |
  GetDashboardUsersError |
  GetRoles |
  GetRolesSuccess |
  GetRolesError |
  GetRoleAttributes |
  GetRoleAttributesSuccess |
  GetRoleAttributesError |
  SelectedUserId |
  GetUser |
  GetUserSuccess |
  GetUserError;
