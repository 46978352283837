import {Component, OnInit, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LayoutService} from '../../services/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from './../../../shared/services/auth/auth.guard';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromSar from '../../../views/sar/state';
import * as userActions from '../../../views/user/state/user.actions';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }];
  public egretThemes;
  public layoutConf: any;

  routerEventSub: Subscription;
  visible: boolean = true;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    private router: Router,
    private store: Store<fromSar.State>,
  ) {
    this.routerEventSub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange) => {
      this.visible =  this.router.url == '/agenda/sourcing-session-agenda' || 
                      this.router.url == '/sar/sar-dashboard' || 
                      this.router.url == '/user/user-dashboard';
    });
  }

  ngOnInit() { 
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
  }

  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  getName(){
    return this.authGuard.getProperty("name");
  }
  getPhotoUrl(){
    let photo = this.authGuard.getProperty("avatarUrl");
    if(photo == null || photo == "") photo = "assets/images/person.png";
    return photo;
  }
  navigateToProfile(){
    let userId = this.authGuard.getProperty('id');
    this.store.dispatch(new userActions.SelectedUserId(userId));
    this.store.dispatch(new userActions.GetUser(userId));
    this.router.navigate(['/user/user-details']);
  }
  signout(){
    this.store.dispatch(new userActions.Logout());
    // this.router.navigate(['/sessions/signin']);
  }

  setLang(e) {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true});

  }
}
