import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRefdata from '../../../refdata/state';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as fromCore from '../../state';
import {Observable} from 'rxjs';
import {IFeedbackModel} from '../../models/i-feedback.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  feedbacks$: Observable<IFeedbackModel[]>;
  isComponentActive: true;

  constructor(private store: Store<fromRefdata.State>,
              public snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.feedbacks$ = this.store.pipe(select(fromCore.selectors.getFeedbacks));

    this.feedbacks$.subscribe(
      feedbacks => {
        if (feedbacks && feedbacks.length > 0) {
          let message = 'An error has ocurred.';
          switch (feedbacks[0].type.toString()) {
            case '0': // ERROR
              message = 'ERROR: ' + feedbacks[0].message;
              break;
            default:
              message = feedbacks[0].message;
              break;
          }

          this.snackBar.open(message, null, {duration: 4000, panelClass: ['global-snackbar'], verticalPosition: 'top'});
        } else {
          this.snackBar.dismiss();
        }
      }
    );
  }
}
