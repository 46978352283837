import {Injectable, Inject, isDevMode} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import { AppConfig } from './../../app-config.module';


@Injectable({
  providedIn: 'root'
})
export class RefdataService {

  cache: Object = {};

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {}

  getIatfMapping<T>(categoryTypeId: number, categoryId: number, subCategoryId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/categoryMapping?categoryTypeId=' + categoryTypeId + '&categoryId=' + categoryId + '&subCategoryId=' + subCategoryId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSuppliers<T>(name:string  = null, code : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/suppliers';
    let params = {};
    if(name != null) params['name'] = name;
    if(code != null) params['code'] = code;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRequestors<T>(name:string  = null, cdsid : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/requestors';
    let params = {};
    if(name != null) params['name'] = name;
    if(cdsid != null) params['cdsid'] = cdsid;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  
  getSupplierCertifications<T>(supplierCode:string  = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/supplierCertifications';
    let params = {};
    if(supplierCode != null) params['supplierCode'] = supplierCode;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRefdataById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/single/' + id;
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getByRefdataTypeId<T>(refdataTypeId: number): Observable<T> {
    const cacheKey = 'SAR.RefData.' + refdataTypeId;
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }
    
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refdataTypeId;
    this.cache[cacheKey] = this.http.get<T>(url)
      .pipe(
        shareReplay(1),
        catchError(HttpUtils.handleHttpClientError)
      );

    return this.cache[cacheKey];
  }

  getRefdataByFilter<T>(refdataTypeId: number, keywords?: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refdataTypeId + (keywords ? '/' + encodeURI(keywords) : '');
    
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getCountriesRefdata<T>(): Observable<T> {
    const cacheKey = 'SAR.Countries';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }

    const url = this.config.getConfig('apiEndpoint') + 'activeCountries';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );

    return this.cache[cacheKey];
  }

  getExchangeRatesRefdata<T>(): Observable<T> {
    const cacheKey = 'SAR.ExchangeRates';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }


    const url = this.config.getConfig('apiEndpoint') + 'activeExchangeRates';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );

    return this.cache[cacheKey];
  }

  getMaterials<T>(materialGroupId?: number, keywords?: string, plantId?: number, categoryId?: number, storageLocationId?: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material';

    let params = {};
    if(keywords != null) params['keywords'] = keywords;
    if(materialGroupId != null) params['materialGroupId'] = materialGroupId;
    if(plantId != null) params['plantId'] = plantId;
    if(categoryId != null) params['itemCategory'] = categoryId;
    if(storageLocationId != null) params['storageLocationId'] = storageLocationId;

    return this.http.get<T>(url, {params: params}).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/single/' + id ;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialsBySarId<T>(sarId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/sar/' + sarId ;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialGroups<T>(): Observable<T> {
    const cacheKey = 'SAR.MaterialGroups';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }

    const url = this.config.getConfig('apiEndpoint') + 'refdatas/materialGroups';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
    return this.cache[cacheKey];
  }

  getAssetNumbers<T>(keywords?: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/assets' + (keywords ? `?keywords=${encodeURI(keywords)}` : '');

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getAssetNumberById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/asset/' + id;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getDepartments<T>(parentId?: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/departments' + (parentId ? `?parentId=${parentId}` : '');

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getDepartment<T>(id: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/department/' + id;
    
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }
    
  getCostCenterByOrderNumberId<T>(id: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/costCenterByOrder/' + id;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
}
