import {Action} from '@ngrx/store';
import {IFeedbackModel} from '../models/i-feedback.model';

export enum CoreActionTypes {
  StartCore = '[Core] Start',
  ShowFeedback = '[Core] Show Feedback',
  ShowFeedbacks = '[Core] Show Feedbacks',
  RemoveFeedback = '[Core] Remove Feedback',
  ShowLoadingIndicator = '[Core] Show Loading',
  HideLoadingIndicator = '[Core] Hide Loading',
}

export class StartCore implements Action {
  readonly type = CoreActionTypes.StartCore;
  constructor(public payload: string) { }
}

export class ShowFeedback implements Action {
  readonly type = CoreActionTypes.ShowFeedback;
  constructor(public payload: IFeedbackModel) { }
}

export class ShowFeedbacks implements Action {
  readonly type = CoreActionTypes.ShowFeedbacks;
  constructor(public payload: IFeedbackModel[]) { }
}

export class RemoveFeedback implements Action {
  readonly type = CoreActionTypes.RemoveFeedback;
  constructor() { }
}

export class ShowLoadingIndicator implements Action {
  readonly type = CoreActionTypes.ShowLoadingIndicator;
  constructor() { }
}

export class HideLoadingIndicator implements Action {
  readonly type = CoreActionTypes.HideLoadingIndicator;
  constructor() { }
}

export type CoreActions =
  StartCore |
  ShowFeedback |
  ShowFeedbacks |
  RemoveFeedback |
  ShowLoadingIndicator |
  HideLoadingIndicator
  ;
