<div class="breadcrumb-bar" *ngIf="this.visible && layout.layoutConf.useBreadcrumb && layout.layoutConf.breadcrumb === 'simple'">
  <ul class="breadcrumb">
    <li *ngFor="let part of routeParts"><a routerLink="/{{part.url}}">{{part.breadcrumb | translate}}</a></li>
  </ul>
</div>

<div class="breadcrumb-title" *ngIf="this.visible && layout.layoutConf.useBreadcrumb && layout.layoutConf.breadcrumb === 'title'">
  <!--<h1 class="bc-title">{{routeParts[routeParts.length -1]['breadcrumb'] | translate}}</h1>-->
  <ul class="breadcrumb" *ngIf="1==1 || routeParts.length > 1">
    <li *ngFor="let part of routeParts"><a routerLink="/{{part.url}}" class="text-muted">{{part.breadcrumb | translate}}</a></li>
  </ul>
</div>


