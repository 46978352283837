import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { IAppModuleModel } from 'src/app/views/refdata/models/i-app-settings.model';
import { AppService } from './app.service';

interface IMenuItem {
  type: string;       // Possible values: link/dropDown/icon/separator/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles: string[];   // @dherbe added to be able to filter item based on user roles
  module: string;
}

interface IChildItem {
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {

  defaultMenu: IMenuItem[] = [
    {
      name: 'Purchase Requests',
      type: 'link',
      tooltip: 'PR Dashboard',
      icon: 'library_books',
      state: 'sar/sar-dashboard',
      roles: ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident'],
      module: "purchase-requests"
    },
    {
      name: 'Purchase Orders',
      type: 'dropDown',
      tooltip: 'Purchase Orders',
      icon: 'library_books',
      state: 'purchase-orders',
      roles : [],
      sub: [
        {
          type: 'link',
          name: "Aggregate PR's",
          state: 'group',
        },
        {
          type: 'link',
          name: 'History',
          state: 'history',
        }
      ],
      module: "purchase-requests"
    },
    {
      name: 'Session Agenda',
      type: 'link',
      tooltip: 'Sourcing Session Agenda',
      icon: 'calendar_today',
      state: 'agenda/sourcing-session-agenda',
      roles: ['Admin','VicePresident'],
      // roles : ['Chairman','Admin','VicePresident']
      module: "purchase-requests"
    },
    {
      name: 'My Activity',
      type: 'link',
      tooltip: 'My Activity Dashboard',
      icon: 'bubble_chart',
      state: 'dashboard',
      roles : ['Buyer','Manager','Director','Admin'],
      module: "purchase-requests"
    },
    // {
    //   name: 'Administration',
    //   type: 'dropDown',
    //   tooltip: 'Administration',
    //   icon: 'admin_panel_settings',
    //   state: 'administration',
    //   roles : ['Admin'],
    //   sub: [
    //     {
    //       type: 'link',
    //       name: "Departments",
    //       state: 'department-settings',
    //     },
    //     {
    //       type: 'link',
    //       name: 'Document types',
    //       state: 'document-settings',
    //     }
    //   ],
    //   module: "admin"
    // },
    {
      name: 'Departments',
      type: 'link',
      tooltip: 'Departments',
      icon: 'insights',
      state: 'administration/department-settings',
      roles : ['Admin'],
      module: "purchase-requests"
    },
    {
      name: 'Users',
      type: 'link',
      tooltip: 'Users',
      icon: 'group',
      state: 'user/user-dashboard',
      roles : ['Admin'],
      module: "admin"
    },
    {
      name: 'Event viewer',
      type: 'link',
      tooltip: 'Event viewer',
      icon: 'clear_all',
      state: 'admin/logging',
      roles : ['SuperUser'],
      module: "admin"
    },
    {
      name: 'Settings',
      type: 'link',
      tooltip: 'Settings',
      icon: 'settings_suggest',
      state: 'settings',
      roles : ['SuperUser'],
      module: "admin"
    },
    {
      name: 'Forecast',
      type: 'link',
      tooltip: 'Forecast',
      icon: 'settings_suggest',
      state: 'forecast',
      roles : ['SuperUser'],
      module: "forecast"
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems;
  // navigation component has subscribed to this Observable
  menuItems$;

  constructor(private appService: AppService) {
    this.filterMenuByAvailableModules();
    this.menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    this.menuItems$ = this.menuItems.asObservable();
  }

  private filterMenuByAvailableModules(){
    const availableModules: IAppModuleModel[] = this.appService.getAvailableModules();
    if (availableModules && availableModules.length){
      this.defaultMenu = this.defaultMenu.filter(menu => 
        availableModules.some(module => 
          module.module == menu.module && (!module.settings?.disabledMenus?.some(state => state == menu.state))
        )
      );
    }
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.defaultMenu);
  }
}
