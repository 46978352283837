<h2 mat-dialog-title>{{'Please contact your system administrator' | translate}}</h2>

    <div>
        <div *ngIf="data && data.message" class="alert-danger">
            <mat-icon>error</mat-icon>&nbsp;{{data.message | translate}}
        </div>
    </div>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{'Close' | translate}}</button>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'Yes' | translate}}</button> -->
</mat-dialog-actions>