import {ILoggedUserModel} from '../models/i-logged-user.model';
import {IFeedbackModel} from '../../core/models/i-feedback.model';
import {UserActions, UserActionTypes} from './user.actions';
import {ISearchUserModel} from '../../sar/models/meeting/i-search-user.model';
import {IUserDashboardModel} from '../models/user-list/i-user-dashboard.model';
import {IRoleModel} from '../models/create-user/i-role.model';
import {IRoleAttributeModel} from '../models/create-user/i-role-attribute.model';
import {IUserModel} from '../models/i-user.model';

export interface UserState {
  feedbacks: IFeedbackModel[];
  token: string;
  name: string;
  userRoles: string[];
  searchedUsers: ISearchUserModel[];
  users: IUserModel[];
  dashboardUsers: IUserDashboardModel[];
  roles: IRoleModel[];
  roleAttributes: IRoleAttributeModel[];
  currentUserId: string;
  currentUser: IUserModel;
}

const initialState: UserState = {
  feedbacks: [],
  token: null,
  name: '',
  userRoles: [],
  searchedUsers: [],
  users: [],
  dashboardUsers: [],
  roles: [],
  roleAttributes: [],
  currentUserId: null,
  currentUser: null
};

export function userReducer(state = initialState, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.InitializeUser:
      return {
        ...state,
        currentUserId: '0'
      };
    case UserActionTypes.CreateSuccess:
      return{
        ...state
      };
    case UserActionTypes.CreateFail:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.UpdateSuccess:
      return{
        ...state
      };
    case UserActionTypes.UpdateFail:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.LoginSuccess:
      return{
        ...state,
        token: action.payload.token,
        name: action.payload.name,
        userRoles: action.payload.roles
      };
    case UserActionTypes.LoginFail:
      return{
        ...state,
        token: null,
        feedbacks: action.payload
      };
    case UserActionTypes.SearchSuccess:
      return{
        ...state,
        searchedUsers: action.payload
      };
    case UserActionTypes.SearchFail:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.GetUsersSuccess:
      return{
        ...state,
        users: action.payload
      };
    case UserActionTypes.GetUsersError:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.GetDashboardUsersSuccess:
      return{
        ...state,
        dashboardUsers: action.payload
      };
    case UserActionTypes.GetDashboardUsersError:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.GetRolesSuccess:
      return{
        ...state,
        roles: action.payload
      };
    case UserActionTypes.GetRolesError:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.GetRoleAttributesSuccess:
      return{
        ...state,
        roleAttributes: action.payload
      };
    case UserActionTypes.GetRoleAttributesError:
      return{
        ...state,
        feedbacks: action.payload
      };
    case UserActionTypes.SelectedUserId:
      return{
        ...state,
        currentUserId: action.payload
      };
    case UserActionTypes.GetUserSuccess:
      return{
        ...state,
        currentUser: action.payload
      };
    case UserActionTypes.GetUserError:
      return{
        ...state,
        feedbacks: action.payload
      };
    default:
      return state;
  }
}
