<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <!-- App Logo -->
    <div class="branding default-bg no-bg" style="background: transparent !important;">
      <!-- Two different logos for dark and light themes -->
      <img src="assets/images/logo-text-white.png" alt="" class="app-logo-text"
          *ngIf="themeService.activatedTheme?.name?.indexOf('dark') !== -1" style="display:none">
      <img src="{{appSettings.logoUrl}}" alt="" class="app-logo-text"
           *ngIf="themeService.activatedTheme?.name?.indexOf('dark') === -1">
    </div>

    <!-- Sidebar user -->
    <div class="app-user">
      <!-- Small buttons -->
      <div class="app-user-controls">
        <button class="text-muted"
                mat-icon-button mat-xs-button matTooltip="{{'Settings' | translate}}"
                [matMenuTriggerFor]="appUserMenu">
          <mat-icon>settings</mat-icon>
        </button>
        <button class="text-muted" style="display:none"
                mat-icon-button  mat-xs-button matTooltip="Inbox"
                routerLink="/inbox">
          <mat-icon>email</mat-icon>
        </button>
        <button class="text-muted"
                mat-icon-button mat-xs-button matTooltip="{{'Sign out' | translate}}"
                routerLink="/sessions/signin">
          <mat-icon>exit_to_app</mat-icon>
        </button>

        <mat-menu #appUserMenu="matMenu">
          <button mat-menu-item (click)="navigateToProfile()">
            <mat-icon>account_box</mat-icon>
            <span>{{'Profile' | translate}}</span>
          </button>
          <button mat-menu-item (click)="signout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{'Sign out' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <!-- Navigation -->
    <app-sidenav (helpClicked)="openHelp($event)" [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
  </div>
</div>


<app-help #sideHelp></app-help>