import {CoreActions, CoreActionTypes} from './core.actions';
import {IFeedbackModel} from '../models/i-feedback.model';

export interface CoreState {
  feedbacks: IFeedbackModel[];
  isLoading: boolean;
}

const initialState: CoreState = {
  feedbacks: [],
  isLoading: false
};

export function coreReducer(state = initialState, action: CoreActions): CoreState {

  switch (action.type) {
    case CoreActionTypes.StartCore:
      return {
        ...state
      };
    case CoreActionTypes.ShowFeedback:
      return {
        ...state,
        feedbacks: [action.payload]
      };
    case CoreActionTypes.ShowFeedbacks:
      return {
        ...state,
        feedbacks: action.payload
      };
    case CoreActionTypes.RemoveFeedback:
      return {
        ...state,
        feedbacks: []
      };
    
    case CoreActionTypes.ShowLoadingIndicator:
      return {
        ...state,
        isLoading: true
      };
    case CoreActionTypes.HideLoadingIndicator:
      return {
        ...state,
        isLoading: false
      };

    default:
      return state;
  }
}
