import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { ErrorComponent } from './components/error/error.component';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  declarations: [ErrorComponent],
  exports: [ErrorComponent]
})
export class CoreModule { }
