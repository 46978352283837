import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {NavigationService} from '../../../shared/services/navigation.service';
import {ThemeService} from '../../services/theme.service';
import {Observable, Subscription} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromUser from '../../../views/user/state';
import {AuthGuard} from './../../../shared/services/auth/auth.guard';
import { Router } from '@angular/router';
import * as userActions from '../../../views/user/state/user.actions';
import { HelpComponent } from '../help/help.component';
import { IAppSettingsModel } from 'src/app/views/refdata/models/i-app-settings.model';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, AfterViewInit {
  @ViewChild('sideHelp') help : HelpComponent;

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public loggedUserName$: Observable<string>;
  
  public appSettings: IAppSettingsModel;
  
  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private storeUser: Store<fromUser.State>,
    private authGuard: AuthGuard,
    private store: Store<fromUser.State>,
    private router: Router,
    private appService: AppService
  ) {
  }

  ngOnInit() {
    this.appSettings = this.appService.getAppSettings();

    this.loggedUserName$ = this.storeUser.pipe(select(fromUser.selectors.getLoggedUserName));
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(items => {
      //@dherbe added ability to filter menuitem based on the user roles
      var menu = [];
      for(let i = 0; i < items.length; i++){
        let canAdd = false;
        if(items[i].roles != null){
          for(let r = 0; r < items[i].roles.length; r++){
            if(this.authGuard.isInRole(items[i].roles[r])){
              canAdd = true;
              break;
            }
          }
        } else {
          canAdd = true;
        }
        if(canAdd) menu.push(items[i]);
      }
      this.menuItems = menu;
      // Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
    });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  navigateToProfile(){
    let userId = this.authGuard.getProperty('id');
    this.store.dispatch(new userActions.SelectedUserId(userId));
    this.store.dispatch(new userActions.GetUser(userId));
    this.router.navigate(['/user/user-details']);
  }
  signout(){
    this.store.dispatch(new userActions.Logout());
    // this.router.navigate(['/sessions/signin']);
  }

  openHelp(evt : any){
    this.help.openWindow();
  }
}
