import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './views/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.auth.getToken();
    if(token!=null){
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
          // 'Content-Type': 'application/json'
        }
      });
    }
    return next.handle(request);
    // .pipe(
      // tap(evt => {
      //   if (evt instanceof HttpResponse) {
      //     if (evt.status == 401){
      //       console.log("######### INTERCEPTOR ########: ", evt);
            
      //       console.log("INTERCEPTOR: ", evt);
      //     }
      //       // if(evt.body && evt.body.success)
      //   }
      // })
    // );
  }
}
